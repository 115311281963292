@use '@angular/material' as mat;
@import "./theme/oms-variables";
@import '../node_modules/ngx-heka-design-lib/styles.scss';
@import "./theme/oms-reset";


.mb-2-5{
  margin-bottom: ($ngx-heka-spacer * 2.5)!important;
}
.oms-card-input-max-width {
  max-width: 440px !important;
}

.oms-card-form-field {
  width: 100% !important;
  min-width: 250px !important;
  //@extend .dense-2;
  @extend .oms-card-input-max-width;

  .mat-mdc-form-field { //dynamic
    width: 100% !important;
    @extend .oms-card-input-max-width;
    min-width: 250px !important;
  }
}

.simple-card-container{
  background-color: white;
  display: flex;
  flex-direction: column;
  min-height: 358px;
  padding: 4rem 5rem ;
  border-radius: 5px;
  justify-content: center;
}
@include media-breakpoint-down(md) {
  .simple-card-container {
    padding: 2rem 2rem;
  }
}

.danger-color {
  color: #DC3545;
}
